import React from "react";
import { SvgIcon } from "@mui/material";

const CheckedIcon = props => {
  return (
    <SvgIcon {...props} viewBox="0 0 55.57 49.54">
      <path
        id="Path_2421"
        data-name="Path 2421"
        d="M57.038,961.347a2.445,2.445,0,0,0-1.661.755c-6.382,6.677-20.774,22.1-28,29.7l-8.492-7.492a2.42,2.42,0,1,0-3.208,3.623l10.266,9.058a2.508,2.508,0,0,0,3.359-.151c6.745-7.057,22.915-24.418,29.59-31.4a2.416,2.416,0,0,0-1.849-4.095ZM3.991,967.405v41.064a2.51,2.51,0,0,0,2.416,2.416H48.678a2.51,2.51,0,0,0,2.416-2.416V987.333a2.416,2.416,0,1,0-4.831,0v18.72H8.822V969.821H41.431a2.416,2.416,0,1,0,0-4.831H6.406A2.581,2.581,0,0,0,3.991,967.405Z"
        transform="translate(-3.991 -961.345)"
      />
    </SvgIcon>
  );
};

export default CheckedIcon;
